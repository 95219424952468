<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shipHistory" />

      <v-container fluid>
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <!-- 取引先-->
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 出荷予定日-->
            <v-text-field
              readonly
              dense
              v-model="shippingScheduleDate"
              :label="$t('label.lbl_shippingScheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete first-search-item">
            <v-autocomplete
              dense
              v-model="workingStatus"
              :items="workingStatusList"
              :label="$t('label.lbl_status')"
              class="txt-singles"
              persistent-hint
              margin-bottom="0"
              pa-3
              @change="
                () => {
                  if (
                    0 <
                    workingStatusList.filter((element) => {
                      return element.value === workingStatus;
                    }).length
                  ) {
                    this.page = 1;
                    getShipHistoryList();
                  }
                }
              "
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </div>
        </v-row>

        <!--ボタン領域-->
        <v-row>
          <div class="search-row-exeBtn">
            <!--戻るボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label" style="float: left">
              <span
                >{{ $t("label.lbl_totalDetail") }}：{{ totalCount
                }}{{ $t("label.lbl_number") }}</span
              >
            </div>

            <!--表示件数切替部分-->
            <div style="float: right">
              <!--表示件数-->
              <div class="text-label" style="clear: both; float: left">
                {{ $t("label.lbl_itemsPerPage") }}
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!--表示件数切替ボタン-->
              <div style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn mandatory class="pasing-btn" value="10" @click="u_10_on()" small>
                    {{ 10 }}
                  </v-btn>
                  <v-btn mandatory class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                    {{ 50 }}
                  </v-btn>
                  <v-btn mandatory class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                    {{ 100 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダー部分 -->
          <template v-slot:[`header.No`]="{ header }">
            <!-- 出荷予定No -->
            <div>
              {{ $t("label.lbl_shippingSchedule") }}<br />
              No
              {{ header.No }}
            </div>
          </template>

          <template v-slot:[`header.shippingScheduleDate`]="{ header }">
            <!-- 予定日 -->
            <div>
              <br />
              {{ $t("label.lbl_schDate") }}
              {{ header.shippingScheduleDate }}
            </div>
          </template>

          <template v-slot:[`header.scheduleQty`]="{ header }">
            <!-- 予定数 -->
            <div>
              <br />
              {{ $t("label.lbl_scheduleQty") }}
              {{ header.scheduleQty }}
            </div>
          </template>

          <template v-slot:[`header.shippingTo`]="{ header }">
            <!-- 出荷先 -->
            <div>
              <br />
              {{ $t("label.lbl_destination") }}
              {{ header.shippingTo }}
            </div>
          </template>

          <template v-slot:[`header.product`]="{ header }">
            <!-- 品番/品名 -->
            <div>
              <br />
              {{ $t("label.lbl_productCnCd") }}
              {{ header.product }}
            </div>
          </template>

          <template v-slot:[`header.lot`]="{ header }">
            <!-- ロット -->
            <v-row>
              <v-col>
                <div>{{ header.text }}</div>
                <div>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`header.productQty`]="{ header }">
            <!-- 予定数実績数 -->
            <div>
              {{ $t("label.lbl_scheduleQty") }}<br />{{ $t("label.lbl_achieveQty") }}
              {{ header.productQty }}
            </div>
          </template>

          <template v-slot:[`header.outLocationNo`]="{ header }">
            <!-- 格納ロケ -->
            <div>
              <br />
              {{ $t("label.lbl_Storagelocation") }}
              {{ header.outLocationNo }}
            </div>
          </template>

          <template v-slot:[`header.status`]="{ header }">
            <!-- 出荷実績ステータス -->
            {{ $t("出荷実績") }}<br />
            {{ $t("label.lbl_status") }}
            {{ header.status }}
          </template>

          <template v-slot:[`header.startTime`]="{ header }">
            <!-- 開始時刻 -->
            <div>
              <br />
              {{ $t("label.lbl_startTime") }}
              {{ header.startTime }}
            </div>
          </template>

          <template v-slot:[`header.endTime`]="{ header }">
            <!-- 終了時刻 -->
            <div>
              <br />
              {{ $t("label.lbl_endTime") }}
              {{ header.endTime }}
            </div>
          </template>
          <template v-slot:[`header.manager`]="{ header }">
            <!-- 担当 -->
            <div>
              <br />
              {{ $t("label.lbl_popDestinationNm") }}
              {{ header.manager }}
            </div>
          </template>
          <!-- * -->

          <!-- アイテム部分 -->
          <!-- 出荷予定No -->
          <template v-slot:[`item.No`]="{ item }">
            <div class="listNo-style">{{ item.No }}</div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <div v-if="item.itemName != null">
              <!-- 品番／品名  改行&ツールチップ -->
              <div v-if="item.itemName.length > 9 || item.itemCd.length > 9">
                <!-- 品名が9文字以上だった場合ツールチップで表示 -->
                <div v-if="item.incidental">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.itemCd }}<br />
                        {{ item.incidental }}<br />
                        {{ item.itemName }}
                      </div>
                    </template>
                    <span
                      >{{ item.itemCd }}<br />{{ item.incidental }}<br />{{ item.itemName }}</span
                    >
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.itemCd }}<br />
                        {{ item.itemName }}
                      </div>
                    </template>
                    <span>{{ item.itemCd }}<br />{{ item.itemName }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div v-else>
                <!-- 品名が9文字以下だった場合ツールチップ無しで表示 -->
                <div v-if="item.incidental">
                  <div>
                    {{ item.itemCd }}<br />
                    {{ item.incidental }}<br />
                    {{ item.itemName }}
                  </div>
                </div>
                <div v-else>
                  <div>
                    {{ item.itemCd }}<br />
                    {{ item.itemName }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- ロット/S/N -->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 300px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <span
                  >{{ item.lot }}<br />
                  {{ item.dueDate }}
                </span>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>

          <template v-slot:[`item.productQty`]="{ item }">
            <!-- 予定数/実績数 -->
            <div>{{ item.scheduleStock }}</div>
            <div v-bind:class="{ isD: item.isNew }">{{ item.outStock }}</div>
          </template>

          <template v-slot:[`item.manager`]="{ item }">
            <div v-if="item.manager != null">
              <!--担当者  改行&ツールチップ -->
              <div v-if="item.manager.length > 9">
                <!-- 担当者が9文字以上だった場合ツールチップで表示 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                      {{ item.manager }}
                    </div>
                  </template>
                  <span>{{ item.manager }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <!-- 担当者が9文字以下だった場合ツールチップ無しで表示 -->
                {{ item.manager }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.detail`]="{ item }">
            <!-- 詳細ボタン -->
            <v-btn small v-if="archiveEditRole == 'true'" @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <!-- * -->
        </v-data-table>

        <div class="text-center pt-2">
          <!-- ページングネーション -->
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- メニュー -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 作業履歴更新モーダル -->
      <v-dialog v-model="isOpenWorkHistory" :max-width="1200">
        <WorkHistory
          :isOpenDetailDialog.sync="isOpenWorkHistory"
          :workHistoryInfo="workHistoryInfo"
          @click="getShipHistoryList"
        />
      </v-dialog>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import WorkHistory from "../common/WorkHistoryUpdate";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import SimpleDialog from "@/components/SimpleDialog";
import { getParameter } from "../../assets/scripts/js/GetParameter";
// import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: "ShipHistory",
  components: {
    Loading,
    sideMenu,
    WorkHistory,
    NavBar,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 作業履歴更新POPUPに渡す値
    workHistoryInfo: {
      outListSubSid: "",
      suppliersSelected: "",
      procCd: "",
      itemCd: "",
      lotNo: "",
      scheduleDate: "",
      itemName: "",
      screenId: "",
      workHistoryInfo: "",
    },
    u_10: false,
    // メニュー
    openMenu: null,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 総件数
    totalCount: 0,
    // 取引先
    suppliersSelected: "",
    // 出荷予定日
    shippingScheduleDate: "",
    // 画面表示用ﾘｽﾄ
    inputList: [],
    // API取得結果ﾘｽﾄ
    resultList: [],
    // 作業履歴更新ダイアログ
    isOpenWorkHistory: false,
    // 予定実績対比画面の値
    serchClientSid: "",
    serchReferenceDateCal: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    // 実績修正権限
    archiveEditRole: "true",
    //作業ステータス
    workingStatus: "01",
    // 作業ステータスドロップダウン
    workingStatusList: [],
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      isOpen: false,
      firstPageFlag: false,
    },
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "6%",
        align: "left",
        sortable: false,
      },
      // 予定日
      {
        text: i18n.tc("label.lbl_schDate"),
        value: "shippingScheduleDate",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 予定数
      {
        text: i18n.tc("label.lbl_scheduleQty"),
        value: "scheduleQty",
        width: "5%",
        align: "right",
        sortable: false,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "shippingTo",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "16%",
        align: "left",
        sortable: false,
      },
      // 予定数実績数
      {
        value: "productQty",
        width: "5%",
        align: "right",
        sortable: false,
      },
      // 格納ロケ
      {
        text: i18n.tc("label.lbl_Storagelocation"),
        value: "outLocationNo",
        align: "left",
        width: "7%",
        sortable: false,
      },
      // 出荷実績ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 終了時刻
      {
        text: i18n.tc("label.lbl_endTime"),
        value: "outEndTime",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 実績数
      {
        text: i18n.tc("label.lbl_achieveQty"),
        value: "outStock",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 担当
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "manager",
        align: "left",
        width: "7%",
        sortable: false,
      },
      // 編集
      {
        text: i18n.tc("label.lbl_edit"),
        value: "detail",
        width: "4%",
        align: "center",
        sortable: false,
      },
    ],
  }),

  created() {
    // 予定実績対比画面からの値を設定する。

    // 予定実績対比画面の検索条件の値
    // 取引先SID
    this.serchClientSid = this.$route.params.serchClientSid;
    // 基準日カレンダー
    this.serchReferenceDateCal = this.$route.params.serchReferenceDateCal;

    // 選択行の値
    // 取引先SID
    this.clientSid = this.$route.params.clientSid;
    // 取引先名
    this.suppliersSelected = this.$route.params.clientName;
    // 出荷予定日（UTC）
    this.shippingScheduleDateJ = this.$route.params.ReferenceDate;
    // 出荷予定日(画面用JST)
    this.shippingScheduleDate = dateTimeHelper
      .convertUTC2JST(this.$route.params.ReferenceDate)
      .substr(0, 10);
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
  },

  methods: {
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;

      this.getShipHistoryList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getShipHistoryList();
    },

    /**
     * 初期化
     */
    async init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      await this.getWorkingStautsPull();
      // 出荷履歴一覧取得
      this.getShipHistoryList();
      // 実績修正権限を取得
      this.archiveEditRole = sessionStorage.getItem("P-COM-006");
    },

    /**
     * 出荷履歴一覧GETAPI
     */
    getShipHistoryList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      // 取引先SID
      config.params.clientSid = this.clientSid;
      // From日（yyyyMMdd 15:00:00.000）
      config.params.outDateFrom = this.shippingScheduleDateJ;
      // To日（yyyyMMdd 14:59:59.999）
      config.params.outDateTo = this.shippingScheduleDate + " 14:59:59.999";
      // 出荷ステータス
      config.params.status =
        (this.workingStatus ?? "") === "" ? this.workingStatusList[0].value : this.workingStatus;

      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      // console.debug("getShipHistoryList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OUTHIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getShipHistoryList() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              let wareOutHistList = [];

              // 初期化
              this.resultList = [];

              // 取得結果格納
              wareOutHistList = jsonData.resIdv.outHist;
              for (var i = 0; i < wareOutHistList.length; i++) {
                let listItem = wareOutHistList[i];

                // 出荷予定数と出荷実績数が異なる場合、色変えフラグをtrue
                let isNewFlag = "";
                if (listItem.scheduleStock != listItem.outStock) {
                  isNewFlag = true;
                } else {
                  isNewFlag = false;
                }

                let inputListItem = {};
                // No
                inputListItem.No = listItem.outListNo;
                // 予定日
                inputListItem.shippingScheduleDate = dateTimeHelper
                  .convertUTC2JST(listItem.outScheduleDate)
                  .substr(0, 10);
                // 予定数（出荷）
                inputListItem.scheduleQty = commonUtil.formatToCurrency(
                  Number(listItem.shipScheduleQuantity)
                );
                // 出荷先SID
                inputListItem.toSid = listItem.toSid;
                // 出荷先名
                inputListItem.shippingTo = listItem.toName;
                // 出荷予定SID
                inputListItem.outListSid = listItem.outListSid;
                // 出荷予定No
                inputListItem.outListNo = listItem.outListNo;
                // 品番
                inputListItem.itemCdShow = listItem.itemCd;
                inputListItem.itemCd = listItem.itemCd.substring(0, 50).trim();
                //付帯品番
                inputListItem.incidental = listItem.itemCd.slice(-50).trim();
                // 品名
                inputListItem.itemName = listItem.itemName;
                // ロットNo、S/N
                let lotNoObj = commonUtil.convertLotNo(listItem.lotNo);
                inputListItem.lotNo = listItem.lotNo;
                inputListItem.lot = lotNoObj.lot;
                inputListItem.dueDate = lotNoObj.dueDate;
                // ステータス
                inputListItem.outStatus = listItem.outStatus;
                // 予定数（明細）
                inputListItem.scheduleStock = commonUtil.formatToCurrency(
                  Number(listItem.scheduleStock)
                );
                if (listItem.outStock != null) {
                  // 実績数（明細）
                  inputListItem.outStock = commonUtil.formatToCurrency(Number(listItem.outStock));
                } else {
                  // 実績数（明細）
                  inputListItem.outStock = "0";
                }
                // 出荷の格納先ロケーションNoTODO:決定次第、対応入れる
                inputListItem.outLocationSid = listItem.outLocationSid;
                // 出荷したロケNo
                inputListItem.outLocationNo = listItem.outLocationNo;
                // 出荷実績ステータス名
                inputListItem.status = listItem.outStatusName;
                if (listItem.outEndTime != null) {
                  // 終了時刻
                  inputListItem.outEndTime = dateTimeHelper
                    .convertUTC2JST(listItem.outEndTime)
                    .substr(0, 19)
                    .replaceAll(" ", "\n");
                }
                // 担当者
                inputListItem.manager = listItem.outUserName;
                // セル色変えフラグ
                inputListItem.isNew = isNewFlag;
                // 出荷伝票明細SID
                inputListItem.outListSubSid = listItem.outListSubSid;
                // 実績修正権限
                inputListItem.archiveEditRole = this.archiveEditRole;
                this.resultList.push(inputListItem);
              }

              this.inputList = [];
              // 画面表示用にデータ修正
              for (var j = 0; j < this.resultList.length; j++) {
                if (0 < j) {
                  // 対象の行と前の行の予定Noを比較
                  if (this.resultList[j].outListSid == this.resultList[j - 1].outListSid) {
                    this.inputList.push(this.resultList[j]);
                    // 前の行と予定Noが同じな場合、空を設定
                    this.inputList[j].No = "";
                    this.inputList[j].shippingScheduleDate = "";
                    this.inputList[j].scheduleQty = "";
                    this.inputList[j].shippingTo = "";
                  } else {
                    // 上記以外の場合はそのまま設定
                    this.inputList.push(this.resultList[j]);
                  }
                } else {
                  // 最初の行はそのまま設定
                  this.inputList.push(this.resultList[j]);
                }
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * メニュー処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 作業履歴更新POPUPに遷移
     */
    openUpdateDelete(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 取引先SID
      this.workHistoryInfo.suppliersSelected = this.clientSid;
      // 工程コード（500：出荷）
      this.workHistoryInfo.procCd = "500";
      // 品番
      this.workHistoryInfo.itemCd = this.inputList[this.editedIndex].itemCdShow;
      // ロット
      this.workHistoryInfo.lotNo = this.inputList[this.editedIndex].lotNo;
      // 予定日
      this.workHistoryInfo.scheduleDate = this.inputList[this.editedIndex].shippingScheduleDate;
      // 品名
      this.workHistoryInfo.itemName = this.inputList[this.editedIndex].itemName;
      // 画面名
      this.workHistoryInfo.screenId = i18n.tc("label.lbl_shipAchieveUpdateDeletepc");
      // ステータス
      //this.workHistoryInfo.status = this.inputList[this.editedIndex].outStatus;
      this.workHistoryInfo.status = "1";
      // 出荷伝票明細SID
      this.workHistoryInfo.outListSubSid = this.inputList[this.editedIndex].outListSubSid;
      this.isOpenWorkHistory = true;
    },

    /**
     * 予定実績対比画面へ渡す値を設定
     */
    backbtn() {
      this.checkFlg = true;
      this.$router.push({
        name: appConfig.MENU_ID.P_MNG_COMP_SCHEDULE,
        params: {
          // 予定実績対比画面「検索条件」の値を保持する。
          // 取引先SID
          clientSid: this.serchClientSid,
          searchclientSid: this.serchClientSid,

          // 基準日
          referenceDate: this.$route.params.ReferenceDate,
          // 基準日カレンダー
          referenceDateCal: this.serchReferenceDateCal,
          arrivalScheduleDateJ: this.shippingScheduleDateJ,
          // 画面遷移フラグ
          checkFlg: this.checkFlg,
        },
      });
      this.updateDialogMessage = false;
    },
    // 再有無プルダウン
    getWorkingStautsPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const dummyPull = getParameter.getCodeMst(appConfig.CODETYPE.WORKING_STATUS_DIV);
      return Promise.all([dummyPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.workingStatusList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.getShipHistoryList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MNG_005_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getShipHistoryList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.isD {
  background-color: $color-difference !important;
}

#listData.v-data-table ::v-deep th {
  padding: 0 12px;
}
</style>
